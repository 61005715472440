*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

.tooltip-arrow, .tooltip-arrow:before {
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
}

.tooltip-arrow {
  visibility: hidden;
}

.tooltip-arrow:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-tooltip-style^="light"] + .tooltip > .tooltip-arrow:before {
  border-style: solid;
  border-color: #e5e7eb;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="top"] > .tooltip-arrow:before {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="right"] > .tooltip-arrow:before {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="bottom"] > .tooltip-arrow:before {
  border-top-width: 1px;
  border-left-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="left"] > .tooltip-arrow:before {
  border-top-width: 1px;
  border-right-width: 1px;
}

.tooltip[data-popper-placement^="top"] > .tooltip-arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^="bottom"] > .tooltip-arrow {
  top: -4px;
}

.tooltip[data-popper-placement^="left"] > .tooltip-arrow {
  right: -4px;
}

.tooltip[data-popper-placement^="right"] > .tooltip-arrow {
  left: -4px;
}

.tooltip.invisible > .tooltip-arrow:before {
  visibility: hidden;
}

[data-popper-arrow], [data-popper-arrow]:before {
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
}

[data-popper-arrow] {
  visibility: hidden;
}

[data-popper-arrow]:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-popper-arrow]:after {
  content: "";
  visibility: visible;
  background: inherit;
  width: 9px;
  height: 9px;
  position: absolute;
  transform: rotate(45deg);
}

[role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: #e5e7eb;
}

.dark [role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: #4b5563;
}

[role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: #e5e7eb;
}

.dark [role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: #4b5563;
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow] {
  bottom: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow] {
  top: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow] {
  right: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow] {
  left: -5px;
}

[role="tooltip"].invisible > [data-popper-arrow]:before, [role="tooltip"].invisible > [data-popper-arrow]:after {
  visibility: hidden;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

select:not([size]) {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 10 6'%3e %3cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m1 1 4 4 4-4'/%3e %3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: .75em .75em;
  padding-right: 2.5rem;
}

[dir="rtl"] select:not([size]) {
  background-position: .75rem;
  padding-left: 0;
  padding-right: .75rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #1c64f2;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked, .dark [type="checkbox"]:checked, .dark [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: .55em .55em;
  border-color: #0000;
}

[type="checkbox"]:checked {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3e %3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3e %3c/svg%3e");
  background-repeat: no-repeat;
  background-size: .55em .55em;
}

[type="radio"]:checked, .dark [type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  background-size: 1em 1em;
}

[type="checkbox"]:indeterminate {
  print-color-adjust: exact;
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3e %3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M0.5 6h14'/%3e %3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: .55em .55em;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto inherit;
}

input[type="file"]::file-selector-button {
  color: #fff;
  cursor: pointer;
  background: #1f2937;
  border: 0;
  margin-inline: -1rem 1rem;
  padding: .625rem 1rem .625rem 2rem;
  font-size: .875rem;
  font-weight: 500;
}

input[type="file"]::file-selector-button:hover {
  background: #374151;
}

[dir="rtl"] input[type="file"]::file-selector-button {
  padding-left: 1rem;
  padding-right: 2rem;
}

.dark input[type="file"]::file-selector-button {
  color: #fff;
  background: #4b5563;
}

.dark input[type="file"]::file-selector-button:hover {
  background: #6b7280;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
}

input[type="range"]:disabled::-webkit-slider-thumb {
  background: #9ca3af;
}

.dark input[type="range"]:disabled::-webkit-slider-thumb {
  background: #6b7280;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1px;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

input[type="range"]::-moz-range-thumb {
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
}

input[type="range"]:disabled::-moz-range-thumb {
  background: #9ca3af;
}

.dark input[type="range"]:disabled::-moz-range-thumb {
  background: #6b7280;
}

input[type="range"]::-moz-range-progress {
  background: #3f83f8;
}

input[type="range"]::-ms-fill-lower {
  background: #3f83f8;
}

.toggle-bg:after {
  content: "";
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  background: #fff;
  border-width: 1px;
  border-color: #d1d5db;
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  position: absolute;
  top: .125rem;
  left: .125rem;
}

input:checked + .toggle-bg:after {
  border-color: #fff;
  transform: translateX(100%);
}

input:checked + .toggle-bg {
  background: #1c64f2;
  border-color: #1c64f2;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3f83f880;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.format {
  color: var(--tw-format-body);
  max-width: 65ch;
}

.format :where([class~="lead"]):not(:where([class~="not-format"] *)) {
  color: var(--tw-format-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.format :where(a):not(:where([class~="not-format"] *)) {
  color: var(--tw-format-links);
  font-weight: 500;
  text-decoration: underline;
}

.format :where(a):not(:where([class~="not-format"] *)):hover {
  text-decoration: none;
}

.format :where(strong):not(:where([class~="not-format"] *)) {
  color: var(--tw-format-bold);
  font-weight: 700;
}

.format :where(a strong):not(:where([class~="not-format"] *)), .format :where(blockquote strong):not(:where([class~="not-format"] *)), .format :where(thead th strong):not(:where([class~="not-format"] *)) {
  color: inherit;
}

.format :where(ol):not(:where([class~="not-format"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.format :where(ol[type="A"]):not(:where([class~="not-format"] *)) {
  list-style-type: upper-alpha;
}

.format :where(ol[type="a"]):not(:where([class~="not-format"] *)) {
  list-style-type: lower-alpha;
}

.format :where(ol[type="A" s]):not(:where([class~="not-format"] *)) {
  list-style-type: upper-alpha;
}

.format :where(ol[type="a" s]):not(:where([class~="not-format"] *)) {
  list-style-type: lower-alpha;
}

.format :where(ol[type="I"]):not(:where([class~="not-format"] *)) {
  list-style-type: upper-roman;
}

.format :where(ol[type="i"]):not(:where([class~="not-format"] *)) {
  list-style-type: lower-roman;
}

.format :where(ol[type="I" s]):not(:where([class~="not-format"] *)) {
  list-style-type: upper-roman;
}

.format :where(ol[type="i" s]):not(:where([class~="not-format"] *)) {
  list-style-type: lower-roman;
}

.format :where(ol[type="1"]):not(:where([class~="not-format"] *)) {
  list-style-type: decimal;
}

.format :where(ul):not(:where([class~="not-format"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.format :where(ol > li):not(:where([class~="not-format"] *))::marker {
  color: var(--tw-format-counters);
  font-weight: 400;
}

.format :where(ul > li):not(:where([class~="not-format"] *))::marker {
  color: var(--tw-format-bullets);
}

.format :where(hr):not(:where([class~="not-format"] *)) {
  border-color: var(--tw-format-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.format :where(blockquote):not(:where([class~="not-format"] *)) {
  color: var(--tw-format-quotes);
  quotes: "“""”""‘""’";
  margin-bottom: 1.6em;
  font-size: 1.11111em;
  font-style: italic;
  font-weight: 700;
}

.format :where(blockquote):not(:where([class~="not-format"] *)):before {
  content: "";
  color: var(--tw-format-quotes);
  background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='24' viewBox='0 0 32 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.6893 24V14.1453C18.6893 6.54 23.664 1.38533 30.6667 -7.15256e-07L31.9933 2.868C28.7507 4.09066 26.6667 7.71867 26.6667 10.6667H32V24H18.6893ZM-9.53674e-07 24V14.1453C-9.53674e-07 6.54 4.99733 1.384 12 -7.15256e-07L13.328 2.868C10.084 4.09066 8 7.71867 8 10.6667L13.3107 10.6667V24H-9.53674e-07Z' fill='%239CA3AF'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  width: 1.77778em;
  height: 1.33333em;
  margin-top: 1.6em;
  display: block;
}

.format :where(blockquote p:first-of-type):not(:where([class~="not-format"] *)):before {
  content: open-quote;
}

.format :where(blockquote p:last-of-type):not(:where([class~="not-format"] *)):after {
  content: close-quote;
}

.format :where(h1):not(:where([class~="not-format"] *)) {
  color: var(--tw-format-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.format :where(h1 strong):not(:where([class~="not-format"] *)) {
  color: inherit;
  font-weight: 900;
}

.format :where(h2):not(:where([class~="not-format"] *)) {
  color: var(--tw-format-headings);
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.format :where(h2 strong):not(:where([class~="not-format"] *)) {
  color: inherit;
  font-weight: 800;
}

.format :where(h3):not(:where([class~="not-format"] *)) {
  color: var(--tw-format-headings);
  margin-top: 0;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.6;
}

.format :where(h3 strong):not(:where([class~="not-format"] *)) {
  color: inherit;
  font-weight: 800;
}

.format :where(h4):not(:where([class~="not-format"] *)) {
  color: var(--tw-format-headings);
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.format :where(h4 strong):not(:where([class~="not-format"] *)) {
  color: inherit;
  font-weight: 700;
}

.format :where(img):not(:where([class~="not-format"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.format :where(figure > *):not(:where([class~="not-format"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.format :where(figcaption):not(:where([class~="not-format"] *)) {
  color: var(--tw-format-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.format :where(code):not(:where([class~="not-format"] *)) {
  color: var(--tw-format-code);
  background-color: var(--tw-format-code-bg);
  border-radius: .222222em;
  padding: .333333em .555556em;
  font-size: .875em;
  font-weight: 600;
}

.format :where(a code):not(:where([class~="not-format"] *)), .format :where(h1 code):not(:where([class~="not-format"] *)) {
  color: inherit;
}

.format :where(h2 code):not(:where([class~="not-format"] *)) {
  color: inherit;
  font-size: .875em;
}

.format :where(h3 code):not(:where([class~="not-format"] *)) {
  color: inherit;
  font-size: .9em;
}

.format :where(h4 code):not(:where([class~="not-format"] *)), .format :where(blockquote code):not(:where([class~="not-format"] *)), .format :where(thead th code):not(:where([class~="not-format"] *)) {
  color: inherit;
}

.format :where(pre):not(:where([class~="not-format"] *)) {
  color: var(--tw-format-pre-code);
  background-color: var(--tw-format-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.format :where(pre code):not(:where([class~="not-format"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.format :where(pre code):not(:where([class~="not-format"] *)):before, .format :where(pre code):not(:where([class~="not-format"] *)):after {
  content: none;
}

.format :where(table):not(:where([class~="not-format"] *)) {
  table-layout: auto;
  text-align: left;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.format :where(thead):not(:where([class~="not-format"] *)) {
  background-color: var(--tw-format-th-bg);
  border-radius: .277778em;
}

.format :where(thead th):not(:where([class~="not-format"] *)) {
  color: var(--tw-format-headings);
  vertical-align: bottom;
  padding: .555556em .571429em .571429em;
  font-weight: 600;
}

.format :where(tbody tr):not(:where([class~="not-format"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-format-td-borders);
}

.format :where(tbody tr:last-child):not(:where([class~="not-format"] *)) {
  border-bottom-width: 0;
}

.format :where(tbody td):not(:where([class~="not-format"] *)) {
  vertical-align: baseline;
}

.format :where(tfoot):not(:where([class~="not-format"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-format-th-borders);
}

.format :where(tfoot td):not(:where([class~="not-format"] *)) {
  vertical-align: top;
}

.format {
  --tw-format-body: #6b7280;
  --tw-format-headings: #111827;
  --tw-format-lead: #6b7280;
  --tw-format-links: #4b5563;
  --tw-format-bold: #111827;
  --tw-format-counters: #6b7280;
  --tw-format-bullets: #6b7280;
  --tw-format-hr: #e5e7eb;
  --tw-format-quotes: #111827;
  --tw-format-quote-borders: #e5e7eb;
  --tw-format-captions: #6b7280;
  --tw-format-code: #111827;
  --tw-format-code-bg: #f3f4f6;
  --tw-format-pre-code: #4b5563;
  --tw-format-pre-bg: #f3f4f6;
  --tw-format-th-borders: #e5e7eb;
  --tw-format-th-bg: #f9fafb;
  --tw-format-td-borders: #e5e7eb;
  --tw-format-invert-body: #9ca3af;
  --tw-format-invert-headings: #fff;
  --tw-format-invert-lead: #9ca3af;
  --tw-format-invert-links: #fff;
  --tw-format-invert-bold: #fff;
  --tw-format-invert-counters: #9ca3af;
  --tw-format-invert-bullets: #4b5563;
  --tw-format-invert-hr: #374151;
  --tw-format-invert-quotes: #f3f4f6;
  --tw-format-invert-quote-borders: #374151;
  --tw-format-invert-captions: #9ca3af;
  --tw-format-invert-code: #fff;
  --tw-format-invert-code-bg: #1f2937;
  --tw-format-invert-pre-code: #d1d5db;
  --tw-format-invert-pre-bg: #374151;
  --tw-format-invert-th-borders: #4b5563;
  --tw-format-invert-td-borders: #374151;
  --tw-format-invert-th-bg: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.format :where(p):not(:where([class~="not-format"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.format :where(blockquote > p:first-child):not(:where([class~="not-format"] *)) {
  margin-top: 0;
}

.format :where(video):not(:where([class~="not-format"] *)), .format :where(figure):not(:where([class~="not-format"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.format :where(li):not(:where([class~="not-format"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.format :where(ol > li):not(:where([class~="not-format"] *)), .format :where(ul > li):not(:where([class~="not-format"] *)) {
  padding-left: .375em;
}

.format :where(.format > ul > li p):not(:where([class~="not-format"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.format :where(.format > ul > li > :first-child):not(:where([class~="not-format"] *)) {
  margin-top: 1.25em;
}

.format :where(.format > ul > li > :last-child):not(:where([class~="not-format"] *)) {
  margin-bottom: 1.25em;
}

.format :where(.format > ol > li > :first-child):not(:where([class~="not-format"] *)) {
  margin-top: 1.25em;
}

.format :where(.format > ol > li > :last-child):not(:where([class~="not-format"] *)) {
  margin-bottom: 1.25em;
}

.format :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-format"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.format :where(hr + *):not(:where([class~="not-format"] *)), .format :where(h2 + *):not(:where([class~="not-format"] *)), .format :where(h3 + *):not(:where([class~="not-format"] *)), .format :where(h4 + *):not(:where([class~="not-format"] *)) {
  margin-top: 0;
}

.format :where(thead th:last-child):not(:where([class~="not-format"] *)) {
  padding-right: 0;
}

.format :where(tbody td, tfoot td):not(:where([class~="not-format"] *)) {
  padding: .571429em;
}

.format :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-format"] *)) {
  padding-right: 0;
}

.format :where(.format > :first-child):not(:where([class~="not-format"] *)) {
  margin-top: 0;
}

.format :where(.format > :last-child):not(:where([class~="not-format"] *)) {
  margin-bottom: 0;
}

.format-sm {
  font-size: 1rem;
  line-height: 1.71429;
}

.format-sm :where(p):not(:where([class~="not-format"] *)) {
  margin-top: 1em;
  margin-bottom: 1em;
}

.format-sm :where([class~="lead"]):not(:where([class~="not-format"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
  font-size: 1.125em;
  line-height: 1.55556;
}

.format-sm :where(blockquote):not(:where([class~="not-format"] *)) {
  margin-bottom: 1.33333em;
}

.format-sm :where(blockquote):not(:where([class~="not-format"] *)):before {
  margin-top: 1.33333em;
}

.format-sm :where(h1):not(:where([class~="not-format"] *)) {
  margin-top: 0;
  margin-bottom: .8em;
  font-size: 1.66667em;
  line-height: 1.2;
}

.format-sm :where(h2):not(:where([class~="not-format"] *)) {
  margin-top: 0;
  margin-bottom: .8em;
  font-size: 1.5em;
  line-height: 1.4;
}

.format-sm :where(h3):not(:where([class~="not-format"] *)) {
  margin-top: 0;
  margin-bottom: .444444em;
  font-size: 1.25em;
  line-height: 1.55556;
}

.format-sm :where(h4):not(:where([class~="not-format"] *)) {
  margin-top: 0;
  margin-bottom: .5em;
  font-size: 1.125em;
  line-height: 1.25;
}

.format-sm :where(img):not(:where([class~="not-format"] *)), .format-sm :where(video):not(:where([class~="not-format"] *)), .format-sm :where(figure):not(:where([class~="not-format"] *)) {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.format-sm :where(figure > *):not(:where([class~="not-format"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.format-sm :where(figcaption):not(:where([class~="not-format"] *)) {
  text-align: center;
  margin-top: .666667em;
  font-size: .875em;
  line-height: 1.33333;
}

.format-sm :where(code):not(:where([class~="not-format"] *)) {
  font-size: .875em;
}

.format-sm :where(h2 code):not(:where([class~="not-format"] *)) {
  font-size: .9em;
}

.format-sm :where(h3 code):not(:where([class~="not-format"] *)) {
  font-size: .888889em;
}

.format-sm :where(pre):not(:where([class~="not-format"] *)) {
  border-radius: .25rem;
  margin-top: 1.66667em;
  margin-bottom: 1.66667em;
  padding: .666667em 1em;
  font-size: .875em;
  line-height: 1.66667;
}

.format-sm :where(ol):not(:where([class~="not-format"] *)), .format-sm :where(ul):not(:where([class~="not-format"] *)) {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 1.375em;
}

.format-sm :where(li):not(:where([class~="not-format"] *)) {
  margin-top: .25em;
  margin-bottom: .25em;
}

.format-sm :where(ol > li):not(:where([class~="not-format"] *)), .format-sm :where(ul > li):not(:where([class~="not-format"] *)) {
  padding-left: .375em;
}

.format-sm :where(.format > ul > li p):not(:where([class~="not-format"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.format-sm :where(.format > ul > li > :first-child):not(:where([class~="not-format"] *)) {
  margin-top: 1em;
}

.format-sm :where(.format > ul > li > :last-child):not(:where([class~="not-format"] *)) {
  margin-bottom: 1em;
}

.format-sm :where(.format > ol > li > :first-child):not(:where([class~="not-format"] *)) {
  margin-top: 1em;
}

.format-sm :where(.format > ol > li > :last-child):not(:where([class~="not-format"] *)) {
  margin-bottom: 1em;
}

.format-sm :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-format"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.format-sm :where(hr):not(:where([class~="not-format"] *)) {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}

.format-sm :where(hr + *):not(:where([class~="not-format"] *)), .format-sm :where(h2 + *):not(:where([class~="not-format"] *)), .format-sm :where(h3 + *):not(:where([class~="not-format"] *)), .format-sm :where(h4 + *):not(:where([class~="not-format"] *)) {
  margin-top: 0;
}

.format-sm :where(table):not(:where([class~="not-format"] *)) {
  font-size: .875em;
  line-height: 1.5;
}

.format-sm :where(thead th):not(:where([class~="not-format"] *)) {
  padding-bottom: .666667em;
  padding-left: 1em;
  padding-right: 1em;
}

.format-sm :where(thead th:first-child):not(:where([class~="not-format"] *)) {
  padding-left: 0;
}

.format-sm :where(thead th:last-child):not(:where([class~="not-format"] *)) {
  padding-right: 0;
}

.format-sm :where(tbody td, tfoot td):not(:where([class~="not-format"] *)) {
  padding: .666667em 1em;
}

.format-sm :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-format"] *)) {
  padding-left: 0;
}

.format-sm :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-format"] *)) {
  padding-right: 0;
}

.format-sm :where(.format > :first-child):not(:where([class~="not-format"] *)) {
  margin-top: 0;
}

.format-sm :where(.format > :last-child):not(:where([class~="not-format"] *)) {
  margin-bottom: 0;
}

.format-base :where(.format > ul > li p):not(:where([class~="not-format"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.format-base :where(.format > ul > li > :first-child):not(:where([class~="not-format"] *)) {
  margin-top: 1.25em;
}

.format-base :where(.format > ul > li > :last-child):not(:where([class~="not-format"] *)) {
  margin-bottom: 1.25em;
}

.format-base :where(.format > ol > li > :first-child):not(:where([class~="not-format"] *)) {
  margin-top: 1.25em;
}

.format-base :where(.format > ol > li > :last-child):not(:where([class~="not-format"] *)) {
  margin-bottom: 1.25em;
}

.format-base :where(.format > :first-child):not(:where([class~="not-format"] *)) {
  margin-top: 0;
}

.format-base :where(.format > :last-child):not(:where([class~="not-format"] *)) {
  margin-bottom: 0;
}

.format-lg :where(.format > ul > li p):not(:where([class~="not-format"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
}

.format-lg :where(.format > ul > li > :first-child):not(:where([class~="not-format"] *)) {
  margin-top: 1.33333em;
}

.format-lg :where(.format > ul > li > :last-child):not(:where([class~="not-format"] *)) {
  margin-bottom: 1.33333em;
}

.format-lg :where(.format > ol > li > :first-child):not(:where([class~="not-format"] *)) {
  margin-top: 1.33333em;
}

.format-lg :where(.format > ol > li > :last-child):not(:where([class~="not-format"] *)) {
  margin-bottom: 1.33333em;
}

.format-lg :where(.format > :first-child):not(:where([class~="not-format"] *)) {
  margin-top: 0;
}

.format-lg :where(.format > :last-child):not(:where([class~="not-format"] *)) {
  margin-bottom: 0;
}

.format-blue {
  --tw-format-links: #2563eb;
  --tw-format-invert-links: #3b82f6;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-\[60px\] {
  bottom: 60px;
}

.end-0 {
  inset-inline-end: 0;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.start-0 {
  inset-inline-start: 0;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-6 {
  top: 1.5rem;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.me-2 {
  margin-inline-end: .5rem;
}

.me-2\.5 {
  margin-inline-end: .625rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ms-0 {
  margin-inline-start: 0;
}

.ms-1 {
  margin-inline-start: .25rem;
}

.ms-2 {
  margin-inline-start: .5rem;
}

.ms-2\.5 {
  margin-inline-start: .625rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-3\.5 {
  margin-top: .875rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-4 {
  width: 1rem;
}

.w-44 {
  width: 11rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.flex-1 {
  flex: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-none {
  transform: none;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-16 {
  gap: 4rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-e-lg {
  border-start-end-radius: .5rem;
  border-end-end-radius: .5rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-s-lg {
  border-start-start-radius: .5rem;
  border-end-start-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-b {
  border-bottom-width: 1px;
}

.border-e-0 {
  border-inline-end-width: 0;
}

.border-t {
  border-top-width: 1px;
}

.\!border-primary-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(29 78 216 / var(--tw-border-opacity)) !important;
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(164 202 254 / var(--tw-border-opacity));
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(28 100 242 / var(--tw-border-opacity));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(26 86 219 / var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-primary-700 {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.\!bg-primary-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity)) !important;
}

.\!bg-primary-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity)) !important;
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(235 245 255 / var(--tw-bg-opacity));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-gray-900\/50 {
  background-color: #11182780;
}

.bg-primary-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.bg-primary-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/30 {
  background-color: #ffffff4d;
}

.bg-white\/50 {
  background-color: #ffffff80;
}

.object-cover {
  object-fit: cover;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(28 100 242 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-primary-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-primary-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-700 {
  transition-duration: .7s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

:is(.dark .dark\:format-invert) {
  --tw-format-body: var(--tw-format-invert-body);
  --tw-format-headings: var(--tw-format-invert-headings);
  --tw-format-lead: var(--tw-format-invert-lead);
  --tw-format-links: var(--tw-format-invert-links);
  --tw-format-bold: var(--tw-format-invert-bold);
  --tw-format-counters: var(--tw-format-invert-counters);
  --tw-format-bullets: var(--tw-format-invert-bullets);
  --tw-format-hr: var(--tw-format-invert-hr);
  --tw-format-quotes: var(--tw-format-invert-quotes);
  --tw-format-quote-borders: var(--tw-format-invert-quote-borders);
  --tw-format-captions: var(--tw-format-invert-captions);
  --tw-format-code: var(--tw-format-invert-code);
  --tw-format-code-bg: var(--tw-format-invert-code-bg);
  --tw-format-pre-code: var(--tw-format-invert-pre-code);
  --tw-format-pre-bg: var(--tw-format-invert-pre-bg);
  --tw-format-th-borders: var(--tw-format-invert-th-borders);
  --tw-format-td-borders: var(--tw-format-invert-td-borders);
  --tw-format-th-bg: var(--tw-format-invert-th-bg);
}

@media (width >= 640px) {
  .sm\:format-base {
    font-size: 1rem;
    line-height: 1.75;
  }

  .sm\:format-base :where(p):not(:where([class~="not-format"] *)) {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }

  .sm\:format-base :where([class~="lead"]):not(:where([class~="not-format"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    font-size: 1.25em;
    line-height: 1.6;
  }

  .sm\:format-base :where(blockquote):not(:where([class~="not-format"] *)) {
    margin-bottom: 1.6em;
  }

  .sm\:format-base :where(blockquote):not(:where([class~="not-format"] *)):before {
    margin-top: 1.6em;
  }

  .sm\:format-base :where(blockquote > p:first-child):not(:where([class~="not-format"] *)) {
    margin-top: 0;
  }

  .sm\:format-base :where(h1):not(:where([class~="not-format"] *)) {
    margin-top: 0;
    margin-bottom: .888889em;
    font-size: 2.25em;
    line-height: 1.11111;
  }

  .sm\:format-base :where(h2):not(:where([class~="not-format"] *)) {
    margin-top: 0;
    margin-bottom: 1em;
    font-size: 1.5em;
    line-height: 1.33333;
  }

  .sm\:format-base :where(h3):not(:where([class~="not-format"] *)) {
    margin-top: 0;
    margin-bottom: .6em;
    font-size: 1.25em;
    line-height: 1.6;
  }

  .sm\:format-base :where(h4):not(:where([class~="not-format"] *)) {
    margin-top: 0;
    margin-bottom: .5em;
    line-height: 1.5;
  }

  .sm\:format-base :where(img):not(:where([class~="not-format"] *)), .sm\:format-base :where(video):not(:where([class~="not-format"] *)), .sm\:format-base :where(figure):not(:where([class~="not-format"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .sm\:format-base :where(figure > *):not(:where([class~="not-format"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:format-base :where(figcaption):not(:where([class~="not-format"] *)) {
    margin-top: .857143em;
    font-size: .875em;
    line-height: 1.42857;
  }

  .sm\:format-base :where(code):not(:where([class~="not-format"] *)), .sm\:format-base :where(h2 code):not(:where([class~="not-format"] *)) {
    font-size: .875em;
  }

  .sm\:format-base :where(h3 code):not(:where([class~="not-format"] *)) {
    font-size: .9em;
  }

  .sm\:format-base :where(pre):not(:where([class~="not-format"] *)) {
    border-radius: .375rem;
    margin-top: 1.71429em;
    margin-bottom: 1.71429em;
    padding: .857143em 1.14286em;
    font-size: .875em;
    line-height: 1.71429;
  }

  .sm\:format-base :where(ol):not(:where([class~="not-format"] *)), .sm\:format-base :where(ul):not(:where([class~="not-format"] *)) {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-left: 1.625em;
  }

  .sm\:format-base :where(li):not(:where([class~="not-format"] *)) {
    margin-top: .5em;
    margin-bottom: .5em;
  }

  .sm\:format-base :where(ol > li):not(:where([class~="not-format"] *)), .sm\:format-base :where(ul > li):not(:where([class~="not-format"] *)) {
    padding-left: .375em;
  }

  .sm\:format-base :where(.format > ul > li p):not(:where([class~="not-format"] *)) {
    margin-top: .75em;
    margin-bottom: .75em;
  }

  .sm\:format-base :where(.format > ul > li > :first-child):not(:where([class~="not-format"] *)) {
    margin-top: 1.25em;
  }

  .sm\:format-base :where(.format > ul > li > :last-child):not(:where([class~="not-format"] *)) {
    margin-bottom: 1.25em;
  }

  .sm\:format-base :where(.format > ol > li > :first-child):not(:where([class~="not-format"] *)) {
    margin-top: 1.25em;
  }

  .sm\:format-base :where(.format > ol > li > :last-child):not(:where([class~="not-format"] *)) {
    margin-bottom: 1.25em;
  }

  .sm\:format-base :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-format"] *)) {
    margin-top: .75em;
    margin-bottom: .75em;
  }

  .sm\:format-base :where(hr):not(:where([class~="not-format"] *)) {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .sm\:format-base :where(hr + *):not(:where([class~="not-format"] *)), .sm\:format-base :where(h2 + *):not(:where([class~="not-format"] *)), .sm\:format-base :where(h3 + *):not(:where([class~="not-format"] *)), .sm\:format-base :where(h4 + *):not(:where([class~="not-format"] *)) {
    margin-top: 0;
  }

  .sm\:format-base :where(table):not(:where([class~="not-format"] *)) {
    font-size: .875em;
    line-height: 1.71429;
  }

  .sm\:format-base :where(thead th):not(:where([class~="not-format"] *)) {
    padding-bottom: .571429em;
    padding-left: .571429em;
    padding-right: .571429em;
  }

  .sm\:format-base :where(thead th:last-child):not(:where([class~="not-format"] *)) {
    padding-right: 0;
  }

  .sm\:format-base :where(tbody td, tfoot td):not(:where([class~="not-format"] *)) {
    padding: .571429em;
  }

  .sm\:format-base :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-format"] *)) {
    padding-right: 0;
  }

  .sm\:format-base :where(.format > :first-child):not(:where([class~="not-format"] *)) {
    margin-top: 0;
  }

  .sm\:format-base :where(.format > :last-child):not(:where([class~="not-format"] *)) {
    margin-bottom: 0;
  }
}

@media (width >= 1024px) {
  .lg\:format-lg {
    font-size: 1.125rem;
    line-height: 1.77778;
  }

  .lg\:format-lg :where(p):not(:where([class~="not-format"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .lg\:format-lg :where([class~="lead"]):not(:where([class~="not-format"] *)) {
    margin-top: 1.09091em;
    margin-bottom: 1.09091em;
    font-size: 1.22222em;
    line-height: 1.45455;
  }

  .lg\:format-lg :where(blockquote):not(:where([class~="not-format"] *)):before {
    margin-top: 1.66667em;
  }

  .lg\:format-lg :where(blockquote > p:first-child):not(:where([class~="not-format"] *)) {
    margin-top: .5em;
  }

  .lg\:format-lg :where(h1):not(:where([class~="not-format"] *)) {
    margin-top: 0;
    margin-bottom: .833333em;
    font-size: 2.66667em;
    line-height: 1;
  }

  .lg\:format-lg :where(h2):not(:where([class~="not-format"] *)) {
    margin-top: 0;
    margin-bottom: .666667em;
    font-size: 2em;
    line-height: 1.33333;
  }

  .lg\:format-lg :where(h3):not(:where([class~="not-format"] *)) {
    margin-top: 0;
    margin-bottom: .666667em;
    font-size: 1.33333em;
    line-height: 1.5;
  }

  .lg\:format-lg :where(h4):not(:where([class~="not-format"] *)) {
    margin-top: 0;
    margin-bottom: .444444em;
    line-height: 1.55556;
  }

  .lg\:format-lg :where(img):not(:where([class~="not-format"] *)), .lg\:format-lg :where(video):not(:where([class~="not-format"] *)), .lg\:format-lg :where(figure):not(:where([class~="not-format"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .lg\:format-lg :where(figure > *):not(:where([class~="not-format"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:format-lg :where(figcaption):not(:where([class~="not-format"] *)) {
    margin-top: 1em;
    font-size: .888889em;
    line-height: 1.5;
  }

  .lg\:format-lg :where(code):not(:where([class~="not-format"] *)) {
    font-size: .888889em;
  }

  .lg\:format-lg :where(h2 code):not(:where([class~="not-format"] *)) {
    font-size: .866667em;
  }

  .lg\:format-lg :where(h3 code):not(:where([class~="not-format"] *)) {
    font-size: .875em;
  }

  .lg\:format-lg :where(pre):not(:where([class~="not-format"] *)) {
    border-radius: .375rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 1.5em;
    font-size: .888889em;
    line-height: 1.75;
  }

  .lg\:format-lg :where(ol):not(:where([class~="not-format"] *)), .lg\:format-lg :where(ul):not(:where([class~="not-format"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
    padding-left: 1.55556em;
  }

  .lg\:format-lg :where(li):not(:where([class~="not-format"] *)) {
    margin-top: .666667em;
    margin-bottom: .666667em;
  }

  .lg\:format-lg :where(ol > li):not(:where([class~="not-format"] *)), .lg\:format-lg :where(ul > li):not(:where([class~="not-format"] *)) {
    padding-left: .444444em;
  }

  .lg\:format-lg :where(.format > ul > li p):not(:where([class~="not-format"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .lg\:format-lg :where(.format > ul > li > :first-child):not(:where([class~="not-format"] *)) {
    margin-top: 1.33333em;
  }

  .lg\:format-lg :where(.format > ul > li > :last-child):not(:where([class~="not-format"] *)) {
    margin-bottom: 1.33333em;
  }

  .lg\:format-lg :where(.format > ol > li > :first-child):not(:where([class~="not-format"] *)) {
    margin-top: 1.33333em;
  }

  .lg\:format-lg :where(.format > ol > li > :last-child):not(:where([class~="not-format"] *)) {
    margin-bottom: 1.33333em;
  }

  .lg\:format-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-format"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .lg\:format-lg :where(hr):not(:where([class~="not-format"] *)) {
    margin-top: 3.11111em;
    margin-bottom: 3.11111em;
  }

  .lg\:format-lg :where(hr + *):not(:where([class~="not-format"] *)), .lg\:format-lg :where(h2 + *):not(:where([class~="not-format"] *)), .lg\:format-lg :where(h3 + *):not(:where([class~="not-format"] *)), .lg\:format-lg :where(h4 + *):not(:where([class~="not-format"] *)) {
    margin-top: 0;
  }

  .lg\:format-lg :where(table):not(:where([class~="not-format"] *)) {
    font-size: .888889em;
    line-height: 1.5;
  }

  .lg\:format-lg :where(thead th):not(:where([class~="not-format"] *)) {
    padding-bottom: .75em;
    padding-left: .75em;
    padding-right: .75em;
  }

  .lg\:format-lg :where(thead th:last-child):not(:where([class~="not-format"] *)) {
    padding-right: 0;
  }

  .lg\:format-lg :where(tbody td, tfoot td):not(:where([class~="not-format"] *)) {
    padding: .75em;
  }

  .lg\:format-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-format"] *)) {
    padding-right: 0;
  }

  .lg\:format-lg :where(.format > :first-child):not(:where([class~="not-format"] *)) {
    margin-top: 0;
  }

  .lg\:format-lg :where(.format > :last-child):not(:where([class~="not-format"] *)) {
    margin-bottom: 0;
  }
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.hover\:\!bg-primary-800:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(225 239 254 / var(--tw-bg-opacity));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 66 159 / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-primary-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.hover\:bg-primary-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(28 100 242 / var(--tw-text-opacity));
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgb(26 86 219 / var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.hover\:text-primary-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hover\:text-primary-700:hover {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border-primary-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:\!ring-primary-300:focus {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity)) !important;
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity));
}

.focus\:ring-gray-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity));
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}

.focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.focus\:ring-gray-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 250 251 / var(--tw-ring-opacity));
}

.focus\:ring-primary-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
}

.focus\:ring-primary-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

.group:hover .group-hover\:bg-white\/50 {
  background-color: #ffffff80;
}

.group:focus .group-focus\:outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group:focus .group-focus\:ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.group:focus .group-focus\:ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

:is(.dark .dark\:divide-gray-700) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}

:is(.dark .dark\:border-blue-500) {
  --tw-border-opacity: 1;
  border-color: rgb(63 131 248 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-600) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-700) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-transparent) {
  border-color: #0000;
}

:is(.dark .dark\:\!bg-primary-600) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity)) !important;
}

:is(.dark .dark\:\!bg-primary-700) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity)) !important;
}

:is(.dark .dark\:bg-blue-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 100 242 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-800\/30) {
  background-color: #1f29374d;
}

:is(.dark .dark\:bg-gray-800\/50) {
  background-color: #1f293780;
}

:is(.dark .dark\:bg-gray-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-900\/80) {
  background-color: #111827cc;
}

:is(.dark .dark\:bg-primary-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-primary-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

:is(.dark .dark\:text-blue-500) {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-200) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-300) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-400) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-500) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-800) {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-primary-500) {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-primary-800) {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:placeholder-gray-400)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}

:is(.dark .dark\:hover\:\!bg-primary-700:hover) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity)) !important;
}

:is(.dark .dark\:hover\:bg-blue-700:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-700:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-800:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-primary-300:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-primary-700:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:text-blue-500:hover) {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-gray-300:hover) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-primary-500:hover) {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-white:hover) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:focus\:border-primary-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

:is(.dark .dark\:focus\:ring-blue-800:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(30 66 159 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-gray-600:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-gray-700:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-gray-800:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-primary-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-primary-800:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-gray-800\/60) {
  background-color: #1f293799;
}

:is(.dark .group:focus .dark\:group-focus\:ring-gray-800\/70) {
  --tw-ring-color: #1f2937b3;
}

@media (width >= 640px) {
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:h-9 {
    height: 2.25rem;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (width >= 768px) {
  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md\:ml-2 {
    margin-left: .5rem;
  }

  .md\:ms-2 {
    margin-inline-start: .5rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:flex {
    display: flex;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
}

@media (width >= 1024px) {
  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mr-2 {
    margin-right: .5rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-4 {
    height: 1rem;
  }

  .lg\:w-4 {
    width: 1rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:border-0 {
    border-width: 0;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .lg\:hover\:text-primary-600:hover {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity));
  }

  :is(.dark .lg\:dark\:hover\:bg-transparent:hover) {
    background-color: #0000;
  }

  :is(.dark .lg\:dark\:hover\:text-primary-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
  }
}

@media (width >= 1280px) {
  .xl\:block {
    display: block;
  }

  .xl\:w-80 {
    width: 20rem;
  }

  .xl\:gap-8 {
    gap: 2rem;
  }
}

.rtl\:rotate-180:where([dir="rtl"], [dir="rtl"] *) {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rtl\:space-x-reverse:where([dir="rtl"], [dir="rtl"] *) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}
/*# sourceMappingURL=index.0a2fe7ec.css.map */
